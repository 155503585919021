import * as React from "react";

export const google_cloud_filled = {
  shape: (
    <React.Fragment>
      <path
        d="M291.38,102.46h14.05l40-40,2-17A179.9,179.9,0,0,0,54.75,133.21a21.84,21.84,0,0,1,14-.84l80-13.2s4.08-6.74,6.18-6.32a99.85,99.85,0,0,1,136.64-10.39Z"
        transform="translate(0.78 -0.42)"
        fill="#e84436"
      />
      <path
        d="M402.46,133.21a180.28,180.28,0,0,0-54.34-87.63l-56.18,56.18A99.85,99.85,0,0,1,328.6,181v10a50,50,0,0,1,0,100h-100l-10,10.11v60l10,10h100a130,130,0,0,0,73.86-237.76Z"
        transform="translate(0.78 -0.42)"
        fill="#517cbe"
      />
      <path
        d="M128.48,370.4h100v-80h-100a49.69,49.69,0,0,1-20.64-4.5l-14,4.36-40.3,40-3.51,14a129.27,129.27,0,0,0,78.5,26.12Z"
        transform="translate(0.78 -0.42)"
        fill="#32a853"
      />
      <path
        d="M128.48,110.75A130,130,0,0,0,50,343.86l58-58a50,50,0,1,1,66.14-66.14l58-58A129.88,129.88,0,0,0,128.48,110.75Z"
        transform="translate(0.78 -0.42)"
        fill="#fbbc0c"
      />
    </React.Fragment>
  ),
  viewBox: `-10 -10 475 380`,
};

export const google_cloud_outline = {
  shape: (
    <React.Fragment>
      <path
        d="M291.38,102.46h14.05l40-40,2-17A179.9,179.9,0,0,0,54.75,133.21a21.84,21.84,0,0,1,14-.84l80-13.2s4.08-6.74,6.18-6.32a99.85,99.85,0,0,1,136.64-10.39Z"
        transform="translate(0.78 -0.42)"
        strokeWidth="57px"
      />
      <path
        d="M402.46,133.21a180.28,180.28,0,0,0-54.34-87.63l-56.18,56.18A99.85,99.85,0,0,1,328.6,181v10a50,50,0,0,1,0,100h-100l-10,10.11v60l10,10h100a130,130,0,0,0,73.86-237.76Z"
        transform="translate(0.78 -0.42)"
        strokeWidth="27px"
      />
      <path
        d="M128.48,370.4h100v-80h-100a49.69,49.69,0,0,1-20.64-4.5l-14,4.36-40.3,40-3.51,14a129.27,129.27,0,0,0,78.5,26.12Z"
        transform="translate(0.78 -0.42)"
        strokeWidth="27px"
      />
      <path
        d="M128.48,110.75A130,130,0,0,0,50,343.86l58-58a50,50,0,1,1,66.14-66.14l58-58A129.88,129.88,0,0,0,128.48,110.75Z"
        transform="translate(0.78 -0.42)"
        strokeWidth="17px"
      />
    </React.Fragment>
  ),
  viewBox: `0 0 461 371`,
};
