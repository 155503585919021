/** @jsx jsx */
import { jsx, ThemeProvider, Heading } from "theme-ui";
import { Themed } from "theme-ui";
// @ts-ignore
import theme from "../gatsby-plugin-theme-ui";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content from "../elements/content";
import Svg from "./svg";
import { UpDown, UpDownWide } from "../styles/animations";
// @ts-ignore
import Intro from "../sections/intro";
import * as st from "../gatsby-plugin-theme-ui/st";
//import "../app.css";

const videoHtml = ` <video width="215" height="464" autoPlay muted loop playsinline>
<source src="video/taxi_demo_video.mp4" type="video/mp4" />
</video> `;

const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <Divider speed={0.2} offset={offset} factor={factor}>
      {/************************************************************/
      /************************************************************/
      /************************************************************/
      /************************************************************/
      /************************************************************/}

      <UpDown>
        <Svg
          icon="s_flutter_color"
          width={250}
          stroke
          color="icon_darker"
          left="5%"
          top="5%"
        />
      </UpDown>
      <UpDownWide>
        <Svg icon="google_cloud_filled" width={40} left="51%" top="55%" />
      </UpDownWide>

      {/************************************************************/
      /************************************************************/
      /************************************************************/
      /************************************************************/
      /************************************************************/}
    </Divider>
    <Content
      sx={{ variant: `texts.bigger` }}
      speed={0.4}
      offset={offset}
      factor={factor}
    >
      <Inner>
        <h1 className="fbs-h1">We are Flutterbase</h1>
        <div className="fbs-subtitle">
          Turn your startup idea into reality with Google Flutter and Cloud
          Native technologies
        </div>
      </Inner>
    </Content>
  </div>
);

export default Hero;
