/** @jsx jsx */
import { jsx } from "theme-ui";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content from "../elements/content";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Svg from "./svg";
import { UpDown, UpDownWide, waveAnimation } from "../styles/animations";
import Footer from "./footer";
// @ts-ignore
import ContactMDX from "../sections/contact";

const Contact = ({
  offset,
  factor = 1,
}: {
  offset: number;
  factor?: number;
}) => (
  <div>
    <Divider fill="divider" speed={0.2} offset={offset} factor={factor}>
      <div
        sx={{
          position: `absolute`,
          bottom: 0,
          width: `full`,
          transform: `matrix(1, 0, 0, -1, 0, 0)`,
        }}
      >
        <div
          sx={{
            position: `relative`,
            height: `full`,
            svg: { width: `100%`, height: `40vh` },
            path: { animation: waveAnimation(`20s`) },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="contact-wave"
            viewBox="0 0 800 338.05"
            preserveAspectRatio="none"
          >
            <path>
              <animate
                attributeName="d"
                values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                repeatCount="indefinite"
                dur="30s"
              />
            </path>
          </svg>
        </div>
      </div>
    </Divider>
    <Content speed={0.4} offset={offset} factor={factor}>
      <Inner>
        <div className="fbs-h2">Get in touch</div>
        <div className="fbs-body">
          Hi, my name is Yakiv Galkin. <br />I am the head of engineering at
          Flutterbase. Our office is located in Melbourne, Australia with the R
          {"&"}
          D team distributed around the globe.
          <br />
          <br />
          Email me at{" "}
          <a href="mailto:hello@flutterbase.com">hello@flutterbase.com</a>.{" "}
          <br />
          Calling all Flutter enthusiasts — connect me on{" "}
          <OutboundLink href="https://www.linkedin.com/in/yakiv/">
            LinkedIn
          </OutboundLink>
          .
        </div>
      </Inner>
      <Footer />
    </Content>
    <Divider speed={0.1} offset={offset} factor={factor}>
      {/*
        
        !!!!!!!!!!!!!!!!!!!
        ICONS GO HERE!!!!!!!
        !!!!!!!!!!
        */}

      <UpDown>
        <Svg
          icon="s_flutter_color"
          width={250}
          stroke
          color="icon_darker"
          left="5%"
          top="15%"
        />
      </UpDown>
      <UpDownWide>
        <Svg icon="dtl_docker" width={170} left="55%" top="50%" />
      </UpDownWide>
    </Divider>
  </div>
);

export default Contact;
