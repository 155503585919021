/** @jsx jsx */
import * as React from "react";
import { jsx } from "theme-ui";
import { hidden } from "../styles/utils";

import { IconType, getIcon } from "../bg_art/all_icons";

type SVGProps = {
  stroke?: boolean;
  color?: string | number | any;
  width: number;
  icon: IconType;
  left: string;
  top: string;
  hiddenMobile?: boolean;
};

const Svg = ({
  stroke = false,
  color = ``,
  width,
  icon,
  left,
  top,
  hiddenMobile = false,
}: SVGProps) => (
  <svg
    sx={{
      opacity: 0.3,
      position: `absolute`,
      stroke: stroke ? `currentColor` : `none`,
      fill: stroke ? `none` : `currentColor`,
      display: hiddenMobile ? hidden : `block`,
      color,
      width,
      left,
      top,
    }}
    viewBox={getIcon(icon).viewBox}
  >
    {getIcon(icon).shape}
  </svg>
);

export default Svg;
